import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import Navbar from '../../../components/Navbar'
import { completeQuiz, getQuestions } from '../../../api'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Quiz = () => {
    const [questions, setQuestions] = useState([]);
    const [qapairs, setqapairs] = useState({})
    const [currentQuiz, setcurrentQuiz] = useState(0)
    const { user } = useSelector(state => state.user);
    const navigate = useNavigate()
    const getData = async () => {
        try {
            const { data } = await getQuestions();
            if (data?.success) {
                setQuestions(data?.data || [])
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                toast.error("You Have already completed quiz");
            }
            navigate('/task');
        }
    }
    useEffect(() => {
        getData();
    }, [user])
    const handleCheck = (id, option) => {
        setqapairs(prev => { return { ...prev, [id]: { isSelect: true, option } } })
    }

    const isSeletable = (id, option, ans) => {
        if (qapairs[id]?.isSelect) {
            if (option == ans) {
                return true
            }
            if (qapairs[id].option == option) {
                if (option == ans) {
                    return 'true'
                } else {
                    return 'wrong'
                }
            }
        } else {
            return;
        }
    }

    const handleSubmit = async () => {
        try {
            const { data } = await completeQuiz();
            if (data.success) {
                toast.success("Task Compelted Successfully");
            }
            navigate('/task')
        } catch (err) {
            if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message || "You Have already completed quiz");
            }
            navigate('/task');
        }
    }

    useEffect(() => {
        if (Object.keys(qapairs).length == questions.length && questions.length > 1) {
            handleSubmit();
        }
    }, [qapairs])

    const backQuestion = () => {
        if (currentQuiz == 0) {
            // toast.success("Quiz Completed Successfully")
            return;
        }
        setcurrentQuiz(prev => prev - 1)
    }

    const nextQuestion = () => {
        if (currentQuiz >= questions.length - 1) {
            handleSubmit();
            return;
        }
        setcurrentQuiz(prev => prev + 1)
    }

    return (
        <>
            <Navbar />
            <section className="mrg_t">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center p-0">
                        {
                            // questions?.map((item) => {
                            //     return (
                            //         <>
                            <div className="col-md-12 col-lg-11 col-xl-10 wow fadeInDown mt-3 " data-wow-delay="1s">
                                <div className="QUIZbg">
                                    <div className="QuizHeading fs-theme">{questions?.[currentQuiz]?.question}</div>
                                    <div className={`quizOption ${isSeletable(questions?.[currentQuiz]?._id, "one", questions?.[currentQuiz]?.answer)}`} onClick={e => handleCheck(questions?.[currentQuiz]._id, 'one')}><span className="pe-2">A.</span> {questions?.[currentQuiz]?.one}</div>
                                    <div className={`quizOption ${isSeletable(questions?.[currentQuiz]?._id, "two", questions?.[currentQuiz]?.answer)}`} onClick={e => handleCheck(questions?.[currentQuiz]._id, 'two')}><span className="pe-2">B.</span> {questions?.[currentQuiz]?.two}</div>
                                    <div className={`quizOption ${isSeletable(questions?.[currentQuiz]?._id, "three", questions?.[currentQuiz]?.answer)}`} onClick={e => handleCheck(questions?.[currentQuiz]._id, 'three')}><span className="pe-2">C.</span> {questions?.[currentQuiz]?.three}</div>
                                    <div className={`quizOption ${isSeletable(questions?.[currentQuiz]?._id, "four", questions?.[currentQuiz]?.answer)}`} onClick={e => handleCheck(questions?.[currentQuiz]._id, 'four')}><span className="pe-2">D.</span> {questions?.[currentQuiz]?.four}</div>
                                </div>
                            </div>
                            //         </>
                            //     )
                            // })
                        }
                        <div className="d-flex justify-content-center">
                            <div className="cusBtn ms-3 ms-md-5 mx-4" style={{ cursor: currentQuiz == 0 ? "not-allowed" : "pointer" }} onClick={backQuestion}>{"⬅️ Back Question"}</div>
                            <div className="cusBtn ms-3 ms-md-5 mx-4" onClick={nextQuestion} style={{ cursor: "pointer" }}>{"Next Question ➡️"}</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Quiz