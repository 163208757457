import React, { useEffect, useState } from 'react'
import ConnectWallet from './ConnectWallet'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getallmenus } from '../api'
import ConnectWallet2 from './ConnectWallet2'

const Navbar = () => {
    const [navActive, setnavActive] = useState(false);
    const [scrollValue, setScrollValue] = useState(window.scrollY)
    const [menus, setMenus] = useState([])
    const { user } = useSelector((state) => state.user)
    const location1 = useLocation();
    const navigate = useNavigate();
    const getmenus = async () => {
        try {
            const { data } = await getallmenus();
            setMenus(data?.data)
            console.log(data?.data);
            let isExists = false
            await Promise.all(data?.data?.map(item => {
                if (item?.path == location1.pathname && item?.status == true) {
                    isExists = true
                } 
                if(location1.pathname == '/quiz') {
                    isExists = true
                }
            }))
            if (!isExists) {
                navigate('/')
            }
        } catch (err) { }
    }
    useEffect(() => {
        getmenus();
    }, [])

    // useEffect(() => {
    //     if (menus?.length) {
    //         let isExists = false
    //         menus.map(item => {
    //             if (item?.path == location1.pathname) {
    //                 console.log("not exists");
    //                 isExists = true
    //             }
    //         })
    //         // console.log("not exists");
    //         if (!isExists) {
    //             navigate('/')
    //         }
    //     }
    // }, [menus])
    
    useEffect(() => {
      window.addEventListener('scroll',e => setScrollValue(window.scrollY))
    }, [])
    
    return (
        <>
            <header className={`wow bounceInDown position-sticky top-0 ${scrollValue && 'nav-dark'}`} data-wow-duration="1s">
                <div className="container-fluid px-5">
                    <div className="d-flex justify-content-start align-items-center my-3">
                        <a href="/"><img src="/images/Logo.png" alt="Logo of Meta Beer Bottle" draggable="false" className="img-fluid logo" /></a>
                        <ul className='d-flex ms-auto align-items-center navbar-big'>
                        <li className="mx-2 nav wow fadeIn " data-wow-delay="1s" onClick={e => setnavActive(false)}><Link to="/" className="navItem NavTxt">Home</Link></li>
                                {
                                    menus?.map(item => {
                                        return item?.status && <li style={{color:"white"}} className="mx-2 nav wow fadeIn" data-wow-delay="1.1s" onClick={e => setnavActive(false)}><Link to={item?.path} className="navItem NavTxt ">{item?.name}</Link></li>
                                    })
                                }
                        </ul>
                        <nav className="ms-3" style={{ zIndex: "9991" }}>
                            <ul className={`navbar ${navActive && 'navbarActive'}`}>
                                <li onClick={e => setnavActive(false)} className="Close wow fadeIn" data-wow-delay="0.9s"><i className="far fa-times"></i></li>
                                <li className="nav wow fadeIn" data-wow-delay="1s" onClick={e => setnavActive(false)}><Link to="/" className="navItem NavTxt ">Home</Link></li>
                                {
                                    menus?.map(item => {
                                        return item?.status && <li className="nav wow fadeIn" data-wow-delay="1.1s" onClick={e => setnavActive(false)}><Link to={item?.path} className="navItem NavTxt ">{item?.name}</Link></li>
                                    })
                                }
                                {/* <li className="nav wow fadeIn" data-wow-delay="1.1s" onClick={e => setnavActive(false)}><Link to="/dailyCheckin" className="navItem NavTxt ">Daily Check in</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.2s" onClick={e => setnavActive(false)}><Link to="/task" className="navItem NavTxt ">Task</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.3s" onClick={e => setnavActive(false)}><Link to="/spin" className="navItem NavTxt ">Spin</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.4s" onClick={e => setnavActive(false)}><Link to="#" className="navItem NavTxt ">Bull Bear</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.5s" onClick={e => setnavActive(false)}><Link to="/refer" className="navItem NavTxt ">Refer & Earn</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.6s" onClick={e => setnavActive(false)}><Link to='/pointredeem' className="navItem NavTxt ">Point Redeem</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.7s" onClick={e => setnavActive(false)}><Link to={'/leaderboard'} className="navItem NavTxt ">Leaderboard</Link></li> */}
                            </ul>
                        </nav>
                        {/* {
                            user.walletAddress ?
                                <div className="NavPoints d-flex justify-content-center align-items-center cl-t ms-auto me-3">
                                    <div className="scorePoints">
                                        <img src="/images/White - Zk Realm Logo.png" className="img-fluid" draggable="false" alt="White color Logo of ZK RealM" />
                                        </div>
                                    <span className="scorePointsText">{user?.points || "00"}</span>
                                </div> : <div className='d-flex justify-content-center align-items-center cl-t ms-auto me-3'></div>
                        } */}
                        <div className="blackBG"></div>
                        <ConnectWallet2></ConnectWallet2>
                        <div className="d-inline-block">
                            <div className="navIcon" onClick={e => setnavActive(!navActive)}><i className="fas fa-bars"></i></div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Navbar