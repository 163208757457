import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  zkSync
} from 'wagmi/chains';
import {
  rainbowWallet,
  walletConnectWallet,
  binanceWallet ,bitgetWallet,okxWallet ,phantomWallet ,coinbaseWallet ,metaMaskWallet,
} from '@rainbow-me/rainbowkit/wallets';

export const config = getDefaultConfig({
  appName: 'RainbowKit App',
  projectId: 'YOUR_PROJECT_ID',
  chains: [
    zkSync,
  ],
  wallets:[{groupName:"Recommended",wallets:[rainbowWallet,walletConnectWallet,binanceWallet,bitgetWallet,okxWallet,phantomWallet,coinbaseWallet,metaMaskWallet   ]}],
  ssr: true,
});